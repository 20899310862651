import i18n from "i18next";
import { initReactI18next } from "react-i18next";

// the translations
// (tip move them in a JSON file and import them)
const resources = {
  en: {
    translation: {
      "toppings": "Toppings",
      "alergens": "ALERGENS",
      "freeSouce": "ADD <b>FREE</b> SAUCE!",
      "freeSouce2": "VALL DISHES INCLUDE ONE SAUCE OF CHOICE<br/>(Checkout \"SOUCES\")",
    }
  },
  lv: {
    translation: {
      "toppings": "Piedevas",
      "alergens": "ALERGĒNI",
      "freeSouce": "PIEVIENO <b> BEZMAKSAS </b> MĒRCI!",
      "freeSouce2": "VISOS ĒDIENOS IEKĻAUTA VIENA MĒRCE PĒC IZVĒLES<br/>(Skatīt sadaļā \"MĒRCES\")",
    }
  }
};

i18n
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    resources,
    lng: "en",

    keySeparator: false, // we do not use keys in form messages.welcome

    interpolation: {
      escapeValue: false // react already safes from xss
    }
  });

export default i18n;
