import styled from "styled-components"

export const Wrap = styled.div`
  margin: 0 auto;
  width: 1200px;
  max-width: 100%;
  position: relative;
`
export const DymmyBg = styled.div`
  background: #000;
  height: 65px;
  position: absolute;
  left: 0;
  width: 100%;
  top: 0;
`
export const Navigation = styled.ul`
  flex: 1;
  display: flex;
  justify-content: center;
  margin-left: 160px;
  @media (max-width: 960px) {
    display: none;
  }
  li {
    display: flex;
    text-align: center;
    a {
      flex: 1;
      display: flex;
      justify-content: center;
      align-items: flex-end;
      border-bottom: 2px solid transparent;
      background: transparent;
      padding: 0 10px 10px;
      text-transform: uppercase;
      font-size: 14px;
      &:hover {
        border-bottom: 2px solid ${({ theme }) => theme.colors.grey1};
        background: ${({ theme }) => theme.colors.grey2};
      }
      &:not(:last-child) {
        margin-right: 15px;
      }
    }
  }
`

export const SocialWrap = styled.ul<{ isMobile: boolean }>`
  display: flex;
  justify-content: ${({ isMobile }) => (isMobile ? "left" : "center")};
  align-items: ${({ isMobile }) => (isMobile ? "center" : "flex-end")};
  padding: ${({ isMobile }) => (isMobile ? "20px 10px" : "0 0 12px 0")};
  li {
    &:not(last-chlid) {
      margin-right: 10px;
    }
  }

`

export const MyLink = styled.a<{ isActive: boolean }>`
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: flex-end;
  border-bottom: 4px solid
    ${({ isActive }) => (isActive ? "#A8A8A9" : "transparent")};
  background: ${({ isActive }) => (isActive ? "#323234" : "transparent")};
  padding: 0 10px 10px;
  text-transform: uppercase;
  font-size: 13px;
  &:hover {
    border-bottom: 4px solid #a8a8a9;
    background: #323234;
  }
  &:not(:last-child) {
    margin-right: 15px;
  }
`
export const LogoWrap = styled.div`
  background-position: center center;
  background-size: contain;
  background-repeat: no-repeat;
`
