import { DefaultTheme } from "styled-components"
export const Theme: DefaultTheme = {
  colors: {
    mainBg: "#242832",
    orange: "#f3a021",
    grey1: "#b2b2b3",
    grey2: "#474b56",
    grey3: "#323234",
  },
}
