import * as React from "react"
import { FunctionComponent } from "react"
import { Theme } from "../../assets/theme/Theme"
import GlobalStyle from "../../assets/GlobalStyle.css"
import { ThemeProvider } from "styled-components"
import '../../translations/i18n';
export type Props = {}
export type Functions = {}

export type AllProps = Props & Functions

export const MainWrap: FunctionComponent<AllProps> = ({ children }) => {
  return (
    <ThemeProvider theme={Theme}>
      <>
        <GlobalStyle />
        {children}
      </>
    </ThemeProvider>
  )
}

export default MainWrap
