import * as React from "react"
import { FunctionComponent, useEffect, useState } from "react"
import { DymmyBg, LogoWrap, Navigation, SocialWrap, Wrap } from "./Header.css"
import { Helmet } from "react-helmet"
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles"
import AppBar from "@material-ui/core/AppBar"
import Toolbar from "@material-ui/core/Toolbar"
import IconButton from "@material-ui/core/IconButton"
import MenuIcon from "@material-ui/icons/Menu"
import { graphql, Link, useStaticQuery } from "gatsby"
import Drawer from "@material-ui/core/Drawer"
import Divider from "@material-ui/core/Divider"
import ListItem from "@material-ui/core/ListItem"
import List from "@material-ui/core/List"
import icon_fb from "../../assets/images/fb.png"
import icon_ig from "../../assets/images/ig.png"
import icon_en from "../../assets/images/en.png"
import icon_lv from "../../assets/images/lv.png"
import { Lang } from "../../constants"
import { useTranslation } from "react-i18next"

export type Props = {
  title: string
  renderSideNav?: () => any
  renderNavTabs?: () => any
  lang: Lang
}
export type Functions = {}
export type AllProps = Props & Functions

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    paperAnchorLeft: { background: "#000", padding: "15px 15px 15px 15px" },
    list: {
      width: 210,
    },
    appbarRoot: {
      background: "#000002",
    },
    toolbarRoot: {
      alignItems: "stretch",
      width: "1200px",
      maxWidth: "100%",
      margin: "0 auto",
    },
    root: {
      flexGrow: 1,
    },
    menuButton: {
      marginRight: theme.spacing(2),
      display: "flex",
      [theme.breakpoints.up("md")]: {
        display: "none",
      },
    },
    title: {
      flexGrow: 1,
    },
    link: {
      color: "#fff",
    },
    desktop: {
      display: "none",
      [theme.breakpoints.up("md")]: {
        display: "flex",
      },
    },
    logo: {
      width: "80px",
      height: "80px",
      marginTop: "4px",
      right: 0,
      [theme.breakpoints.up("md")]: {
        display: "flex",
        marginTop: "10px",
        width: "170px",
        height: "170px",
        left: 0,
        top: 0,
        transform: `translateX(0)`,
      },
    },
  })
)

export const Header: FunctionComponent<AllProps> = ({
  title,
  renderSideNav,
  renderNavTabs,
  lang,
}) => {
  const classes = useStyles()
  const [isOpen, setIsOpen] = useState(false)
  const { t, i18n } = useTranslation()

  useEffect(() => {
    i18n.changeLanguage(lang)
  }, [lang])

  const data = useStaticQuery(graphql`
    {
      allMarkdownRemark(filter: { fields: { collection: { eq: "home" } } }) {
        nodes {
          frontmatter {
            title
            subTitle
            image
            logo
          }
        }
      }
    }
  `)

  const pages = [
    {
      path: "/",
      title: lang === Lang.LV ? "Sākums" : "Home",
    },
    {
      path: "/menu",
      title: lang === Lang.LV ? "Ēdienkarte" : "Menu",
    },
    {
      path: "/drinks",
      title: lang === Lang.LV ? "Dzērieni" : "Drinks",
    },
    {
      path: "/location",
      title: lang === Lang.LV ? "Atrašanās vieta" : "Location",
    },
    {
      path: "/delivery",
      title: lang === Lang.LV ? "Piegāde" : "Delivery",
    },
    /*
    {
      path: "/jobs",
      title: lang === Lang.LV ? "Vakances" : "Jobs",
    },
    */
  ]
  const links = pages.map(({ path, title }) => ({
    title,
    href: lang === Lang.LV ? path : `/${lang}${path}`,
  }))

  const social = (isMobile = false) => {
    return (
      <SocialWrap isMobile={isMobile}>
        <li>
          <a href="https://www.facebook.com/FontaineDelisnack" target={"_blank"}>
            <img src={icon_fb} />
          </a>
        </li>
        <li>
          <a href="/">
            <img src={icon_lv} />
          </a>
        </li>
        <li>
          <a href={`/${Lang.EN}`}>
            <img src={icon_en} />
          </a>
        </li>
      </SocialWrap>
    )
  }
  const logo = data.allMarkdownRemark.nodes[0].frontmatter.logo

  return (
    <>
      <DymmyBg />
      <Wrap>
        <Helmet>
          <title>{title}</title>
          <meta name="description" content="Delisnack restorāni" />
        </Helmet>

        <Drawer
          open={isOpen}
          onClose={() => {
            setIsOpen(false)
          }}
          classes={{ paperAnchorLeft: classes.paperAnchorLeft }}
        >
          <div className={classes.list}>
            <List>
              {links.map(({ title, href }, index) => (
                <ListItem
                  button
                  key={title}
                  style={{ padding: "15px 10px", color: "#fff" }}
                >
                  <Link to={href}>{title}</Link>
                </ListItem>
              ))}
              <Divider
                style={{ backgroundColor: "#545454", margin: "20px 0 15px" }}
              />
              {social(true)}
            </List>
          </div>
        </Drawer>
        <AppBar position="fixed" classes={{ root: classes.appbarRoot }}>
          <Toolbar
            classes={{
              root: classes.toolbarRoot,
            }}
          >
            <Link to="/">
              <LogoWrap
                className={classes.logo}
                style={{
                  backgroundImage: `url(${logo})`,
                  position: "absolute",
                }}
              />
            </Link>

            {renderSideNav && renderSideNav()}

            <IconButton
              edge="start"
              className={classes.menuButton}
              color="inherit"
              aria-label="menu"
              onClick={() => setIsOpen(true)}
            >
              <MenuIcon />
            </IconButton>

            <Navigation className={classes.desktop}>
              {links.map(({ title, href }, index) => (
                <li key={index}>
                  <Link
                    to={href}
                    activeStyle={{
                      background: "#474b56",
                      borderColor: "#b2b2b3",
                    }}
                  >
                    {title}
                  </Link>
                </li>
              ))}
            </Navigation>
            <div className={classes.desktop}>{social()}</div>
          </Toolbar>
        </AppBar>

        {renderNavTabs && renderNavTabs()}
      </Wrap>
    </>
  )
}

export default Header
