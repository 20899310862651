import { createGlobalStyle } from "styled-components"
import styled from "styled-components"
const fontBlack = require("./fonts/ProximaNova-Black.woff")
const fontBold = require("./fonts/ProximaNova-Bold.woff")
const fontRegular = require("./fonts/ProximaNova-Regular.woff")
const fontSemiBold = require("./fonts/ProximaNova-Semibold.woff")
const fontSemiCondSemibold = require("./fonts/ProximaNovaExCn-Semibold.woff")
const fontSemiCondBold = require("./fonts/ProximaNovaExCn-Bold.woff")

const GlobalStyle = createGlobalStyle`
    @font-face {
        font-family: 'Font-Regular';
        src: url(${fontRegular});
        font-weight: normal;
        font-style: normal;
    }
    @font-face {
        font-family: 'Font-Black';
        src: url(${fontBlack});
        font-weight: normal;
        font-style: normal;
    }
    @font-face {
        font-family: 'Font-Bold';
        src: url(${fontBold});
        font-weight: normal;
        font-style: normal;
    }
    @font-face {
        font-family: 'Font-Semibold';
        src: url(${fontSemiBold});
        font-weight: normal;
        font-style: normal;
    }
    
    @font-face {
        font-family: 'Font-Cond-Semibold';
        src: url(${fontSemiCondSemibold});
        font-weight: normal;
        font-style: normal;
    }
    @font-face {
        font-family: 'Font-Cond-Bold';
        src: url(${fontSemiCondBold});
        font-weight: normal;
        font-style: normal;
    }
    html, body, div, span, applet, object, iframe,
    h1, h2, h3, h4, h5, h6, p, blockquote, pre,
    a, abbr, acronym, address, big, cite, code,
    del, dfn, em, img, ins, kbd, q, s, samp,
    small, strike, strong, sub, sup, tt, var,
    b, u, i, center,
    dl, dt, dd, ol, ul, li,
    fieldset, form, label, legend,
    table, caption, tbody, tfoot, thead, tr, th, td,
    article, aside, canvas, details, embed,
    figure, figcaption, footer, header, hgroup,
    menu, nav, output, ruby, section, summary,
    time, mark, audio, video {
        margin: 0;
        padding: 0;
        border: 0;
        font-size: 100%;
        font: inherit;
        vertical-align: baseline;
        font-family: Font-Regular;
    }
    article, aside, details, figcaption, figure,
        footer, header, hgroup, menu, nav, section {
        display: block;
    }
    html, body{
        height: 100%;
        color:#fff;
        background: ${({ theme }) => theme.colors.mainBg};
    }
    a{
        text-decoration: none;
        color:inherit;
    }
    body {
        line-height: 1;
    }
    ol, ul {
        list-style: none;
    }
    blockquote, q {
        quotes: none;
    }
    blockquote:before, blockquote:after,
        q:before, q:after {
        content: '';
        content: none;
    }
    table {
        border-collapse: collapse;
        border-spacing: 0;
    }
    *, *:before, *:after {
      -webkit-box-sizing: border-box;
      -moz-box-sizing: border-box;
      box-sizing: border-box;
    }
    * {
      -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
    }
    input[type="text"],input[type="email"],input[type="tel"], input[type="phone"] {
        -webkit-appearance: none!important;
        -moz-appearance: none!important;
        appearance: none!important;
    }
    input:focus, button:focus {outline:0;}
    body{
        padding-top: 60px;
    }
    
`

export default GlobalStyle
